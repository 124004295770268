import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Interface
import { ReferencesProps } from './ReferencesShell'
import Grid from './Layout/Grid'
import Filters from 'components/elements/Misc/Filters'

const Section = styled.section`
  z-index: 2;
`

const FiltersWrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
`

const Overview: React.FC<ReferencesProps> = ({ fields }) => {
  const {
    allWpReference,
    allWpReferencecategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.referencesQueryQuery>(graphql`
    query referencesQuery {
      allWpReference {
        edges {
          node {
            title
            recap {
              delivery
              scope
              shipyard
              owner
            }
            referencecategories {
              nodes {
                id
                databaseId
                name
              }
            }
          }
        }
      }

      allWpReferencecategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const references: unknown = allWpReference.edges

  return (
    <Section className="position-relative pb-5">
      <div className="pb-5">
        <BlogBink
          posts={references as BlogBinkPosts}
          id="overview-references"
          categories={allWpReferencecategory.edges}
          categoryName="referencecategories"
          limit={Number(fields.limit)}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <FiltersWrapper className="mt-5">
                  <Filters />
                </FiltersWrapper>
              </div>
            </div>
          </div>
          <Grid fields={fields} />
        </BlogBink>
      </div>
    </Section>
  )
}

export default Overview
